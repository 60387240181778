import { useState } from "react";
import "../HeaderComponents/MainHeader.css";
import LogoSection from "./LogoSection";
import SearchSection from "./SearchSection";
import IconsSection from "./IconsSection";
import ProfileSection from "./ProfileSection";

export default function MainHeader({
  handelLogoClick,
  files,
  handleFileSearch,
  handleFeedback,
  name,
  onLogout,
  email,
  photoURL,
  accessToken,
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  function toggleDrawer() {
    setIsDrawerOpen((prev) => !prev);
  }

  return (
    <>
      <header id="mainHeader" className="header1">
        <LogoSection handelLogoClick={handelLogoClick} />
        <SearchSection files={files} handleFileSearch={handleFileSearch} />
        <IconsSection
          handleFeedback={handleFeedback}
          photoURL={photoURL}
          toggleDrawer={toggleDrawer}
        />
      </header>
      <ProfileSection
        name={name}
        onLogout={onLogout}
        photoURL={photoURL}
        email={email}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        accessToken={accessToken}
      />
      {isDrawerOpen && (
        <div className="drawer-overlay" onClick={toggleDrawer}></div>
      )}
    </>
  );
}