import "../newComponent/Header.css";

import { useState } from "react";

import CommonOverlay from "../newComponent/CommonOverlay";
import UploadOverlay from "../components/UploadOverlay";
import MainHeader from "./HeaderComponents/MainHeader";

function Header1({
  email,
  onLogout,
  name,
  photoURL,
  files,
  handleFileSearch,
  accessToken,
  handelLogoClick,
}) {
  const [isFeedbackOverlayOpen, setIsFeedbackOverlayOpen] = useState(false);

  const handleFeedback = async (e) => {
    setIsFeedbackOverlayOpen(true);
  };
  const closeFeedbackOverLay = () => {
    setIsFeedbackOverlayOpen(false);
  };

  return (
    <>
      <MainHeader
        handelLogoClick={handelLogoClick}
        files={files}
        handleFileSearch={handleFileSearch}
        handleFeedback={handleFeedback}
        name={name}
        onLogout={onLogout}
        email={email}
        photoURL={photoURL}
        accessToken={accessToken}
      />

      {isFeedbackOverlayOpen && (
        <CommonOverlay
          isOpen={isFeedbackOverlayOpen}
          onClose={closeFeedbackOverLay}
          size="medium"
          title="Feedback"
        >
          <UploadOverlay
            accessToken={accessToken}
            onClose={closeFeedbackOverLay}
            isOpen={isFeedbackOverlayOpen}
            isUploadForFeedback={"true"}
          />
        </CommonOverlay>
        // <UploadOverlay
        //   accessToken={accessToken}
        //   onClose={closeFeedbackOverLay}
        //   isOpen={isFeedbackOverlayOpen}
        //   isUploadForFeedback={"true"}
        // />
      )}
    </>
  );
}

export default Header1;
