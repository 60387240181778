import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name:'',
  email: '',
  photoURL: '',
  accessToken: '',
  roles: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRoles(state, action) {
      state.roles = [...action.payload];
    },
  },
});

export const { setRoles } = userSlice.actions;
export default userSlice.reducer;