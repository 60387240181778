import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div style={{ marginLeft: "-60px" }} >
      <select
        id="languageDropdown"
        className="dropdown-style"
        onChange={(e) => changeLanguage(e.target.value)}
        // defaultValue={i18n.language} // Set the current language as default
      >
        <option value="en">English</option>
        <option value="es">Español</option>
        <option value="fr">Français</option>
        <option value="de">Deutsch</option>
        <option value="ja">日本語</option>
        <option value="it">Italiano</option>
        <option value="ru">Русский</option>
        <option value="zhcn">中文</option>
        <option value="tr">Türkçe</option>
      </select>
    </div>
  );
};

export default LanguageSwitcher;
