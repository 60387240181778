import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import translationEN from './locales/en/translation.json';
import translationSP from './locales/es/translation.json';
import translationFR from './locales/fr/translation.json';
import translationDE from './locales/de/translation.json';
import translationJA from './locales/ja/translation.json';
import translationIT from './locales/it/translation.json';
import translationRU from './locales/ru/translation.json';
import translationZHCN from './locales/zh-cn/translation.json';
import translationTR from './locales/tr/translation.json';

// Define the resources
const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationSP
  },
  fr: {
    translation: translationFR
  },
  de: {
    translation: translationDE
  },
  ja: {
    translation: translationJA
  },
  it: {
    translation: translationIT
  },
  ru: {
    translation: translationRU
  },
  zhcn: {
    translation: translationZHCN
  },
  tr: {
    translation: translationTR
  }
};

i18n
  .use(initReactI18next) // Passes i18n instance to react-i18next.
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language if the current language doesn't have the translation
    interpolation: {
      escapeValue: false // React already does escaping
    }
  });

export default i18n;
